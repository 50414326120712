<template>
  <div class="animated fadeIn">
    <LoadingWidget v-if="initStatus != 1" :status="initStatus" @init="init" />

    <b-card v-else>
      <template v-slot:header>
        <h5>Kategori FPP</h5>
      </template>
      <div class="search-wrapper d-flex align-items-center justify-content-between">
        <div>
          <b-button class="mb-3 mr-2" variant="pgiBtn" name="kategoriFPPAddBtn"
            @click="$bvModal.show('m-create-kategori-fpp')"><i class="fa fa-plus fs-6"></i> Tambah Data</b-button>
        </div>
      </div>

      <b-table bordered hover show-empty :busy="isBusy" :items="kategoriFPP" :fields="fields" :perPage="perPage"
        responsive class="mb-3">
        <template #empty="scope"> Data tidak ditemukan </template>

        <template v-slot:table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle" variant="secondary"></b-spinner>
          </div>
        </template>

        <template v-slot:cell(sla)="row">
          <div v-if="row.item.sla">{{ row.item.sla }} Hari</div>

          <div v-else>Data tidak ditemukan</div>
        </template>

        <template v-slot:cell(action)="row">
          <div class="no-wrap">
            <b-button class="mr-1 btn btn-sm btn-warning" v-b-tooltip.hover title="Edit" name="btnEditKategoriFPP" @click="
              showModalEdit(
                `${row.item.id}`,
                `${row.item.id_jenis}`,
                `${row.item.name}`,
                `${row.item.sla}`
              )
              ">
              <i class="fa fa-pencil"></i>
            </b-button>
            <b-button size="sm" class="ml-1 btn btn-sm btn-danger" v-b-tooltip.hover title="Delete"
              name="btnDeleteKategoriFPP" @click="
                showSweetAlertDelete(`${row.item.id}`, `${row.item.name}`)
                ">
              <i class="fa fa-trash"></i>
            </b-button>
          </div>
        </template>
      </b-table>

      <div class="pagination-wrapper d-flex align-items-center justify-content-between">
        <b-input-group prepend="Menampilkan" class="mt-3 font-weight-bold">
          <b-input-group-append>
            <b-input-group-text class="bg-white">
              <strong class="mr-1">{{ totalViewText }}</strong>
              <span class="mr-1">dari</span>
              <strong>{{ rows }}</strong>
            </b-input-group-text>
            <b-input-group-text>entri</b-input-group-text>
          </b-input-group-append>
        </b-input-group>
        <b-pagination class="mb-0" v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="my-table"
          @input="onPageChange"></b-pagination>
      </div>
    </b-card>

    <!-- Modal Create -->
    <b-modal id="m-create-kategori-fpp" class="modal-lg" title="Kategori FPP" ref="my-modal-kategori-fpp"
      @hidden="hideModal" no-close-on-backdrop no-close-on-esc hide-footer>
      <b-form @submit.prevent="onConfirm">
        <b-form-group id="fg-create-kategori-fpp" label="Kategori" label-for="input-kategori-fpp" class="required">
          <b-form-input id="input-kategori-sla-fpp" type="text" v-model.trim="form.name"
            placeholder="Masukkan Kategori"></b-form-input>
          <small v-if="!$v.form.name.required && $v.form.name.$dirty" class="text-danger">Kolom tidak boleh kosong</small>
        </b-form-group>

        <b-form-group id="fg-create-kategori-jenis-fpp" label="Kategori Jenis FPP" label-for="select-kategori-jenis-fpp"
          class="required">
          <b-form-select v-model="form.id_kategori_jenis_fpp" size="md" :plain="false">
            <template v-slot:first>
              <b-form-select-option :value="null" disabled>Pilih Kategori Jenis</b-form-select-option>
            </template>
            <b-form-select-option v-for="option in optionsKategoriJenisFPP" v-bind:key="option.id"
              v-bind:value="option.id">
              {{ option.name }}
            </b-form-select-option>
          </b-form-select>
          <span v-if="!$v.form.id_kategori_jenis_fpp.required &&
            $v.form.id_kategori_jenis_fpp.$dirty
            " class="text-danger">Kolom tidak boleh kosong</span>
        </b-form-group>

        <div class="mt-4 text-right">
          <b-button variant="pgiBtn" type="submit"><i class="fa fa-save"></i> Simpan</b-button>
          <b-button class="ml-2" variant="dark" @click="hideModal"><i class="fa fa-times"></i> Tutup</b-button>
        </div>
      </b-form>
    </b-modal>

    <!-- MODAL EDIT -->
    <b-modal id="m-edit-kategori-fpp" class="modal-lg" title="Ubah Kategori FPP" ref="my-modal-kategori-fpp"
      @hidden="hideModal" no-close-on-backdrop no-close-on-esc hide-footer>
      <b-form @submit.prevent="onConfirmEdit">
        <b-form-group id="fg-edit-kategori-fpp" label="Kategori FPP" label-for="edit-kategori-fpp" class="required">
          <b-form-input id="input-kategori-sla-fpp" type="text" v-model.trim="formEdit.name"
            placeholder="Masukkan Kategori"></b-form-input>
          <small v-if="!$v.formEdit.name.required && $v.formEdit.name.$dirty" class="text-danger">Kolom tidak boleh
            kosong</small>
        </b-form-group>

        <b-form-group id="fg-edit-kategori-jenis-fpp" label="Kategori Jenis FPP" label-for="select-kategori-jenis-fpp"
          class="required">
          <b-form-select v-model="formEdit.id_kategori_jenis_fpp" size="md" :plain="false">
            <b-form-select-option v-for="option in optionsKategoriJenisFPP" v-bind:key="option.id"
              v-bind:value="option.id">
              {{ option.name }}
            </b-form-select-option>
          </b-form-select>
          <span v-if="!$v.formEdit.id_kategori_jenis_fpp.required &&
            $v.formEdit.id_kategori_jenis_fpp.$dirty
            " class="text-danger">Kolom tidak boleh kosong</span>
        </b-form-group>

        <div class="mt-4 text-right">
          <b-button variant="pgiBtn" type="submit"><i class="fa fa-save"></i> Simpan Perubahan</b-button>
          <b-button class="ml-2" variant="dark" @click="hideModal"><i class="fa fa-times"></i> Tutup</b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { validationMixin } from "/node_modules/vuelidate";
import { required } from "/node_modules/vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  data() {
    return {
      initStatus: 0,
      isBusy: true,
      form: {
        name: null,
        sla: null,
        id_kategori_jenis_fpp: null,
      },
      formEdit: {
        id: null,
        name: null,
        sla: null,
        id_kategori_jenis_fpp: null,
      },
      formDelete: {
        activeName: null,
        activeId: null,
      },
      fields: [
        {
          key: "category",
          label: "Kategori Pengajuan",
          thClass: "nameOfTheClassTh",
          sortable: true,
        },
        {
          key: "kategori_jenis",
          label: "Jenis Pengajuan",
          thClass: "nameOfTheClassTh",
          tdClass: "nameOfTheClassTd",
          sortable: true,
        },
        {
          key: "action",
          label: "Aksi",
          thClass: "nameOfTheClassThAction",
          tdClass: "nameOfTheClassTd",
        },
      ],
      optionKategori: [
        {
          value: "Mendesak",
          label: "Mendesak",
        },
        {
          value: "Sedang",
          label: "Sedang",
        },
        {
          value: "Tidak Mendesak",
          label: "Tidak Mendesak",
        },
      ],
      kategoriFPP: [],
      kategoriFPPByID: [],
      optionsKategoriJenisFPP: [],
      outputKategoriJenisFPP: "",
      outputKategoriJenisFPPForEdit: "",
      isModalVisible: false,
      currentPage: 1,
      perPage: null,
      rows: null,
      totalViewText: "",
      page: "",
      size: "",
      from: "",
      limit: "",
      loading: false,
      query: "",
    };
  },
  validations: {
    form: {
      name: {
        required,
      },
      id_kategori_jenis_fpp: {
        required,
      },
      sla: {
        required,
      },
    },

    formEdit: {
      name: {
        required,
      },
      id_kategori_jenis_fpp: {
        required,
      },
      sla: {
        required,
      },
    },
  },

  //----------------- Init Function -----------------//
  methods: {
    init: function () {
      let getAllData = "";
      this.initStatus = 0;

      // Get Kategori
      getAllData = this.$axios
        .get("api/internal-memo/kategori")
        .then((response) => {
          let datas = response.data.data;

          for (let data of datas.data) {
            for (let jenis of data.kategori_jenis) {
              var kategori_jenis = jenis.name;
            }

            this.kategoriFPP.push({
              id: data.id,
              id_jenis: data.id_kategori_jenis_fpp,
              category: data.name,
              name: data.name,
              kategori_jenis: kategori_jenis,
              sla: data.sla,
            });
          }

          this.perPage = datas.per_page;
          this.size = this.perPage;
          this.from = datas.from;
          this.limit = datas.to;

          this.updateTotalItem(datas.total);
          let msg = this.$helper.getFlash();
          this.$helper.toastSucc(this, msg);
        });

      // Get Kategori Jenis
      getAllData = this.$axios
        .get("api/internal-memo/kategori-jenis/all")
        .then((response) => {
          let dataKategoriJenisFPP = response.data.data;

          for (var i = 0; i < dataKategoriJenisFPP.length; i++) {
            this.optionsKategoriJenisFPP.push({
              id: dataKategoriJenisFPP[i].id,
              name: dataKategoriJenisFPP[i].name,
            });
          }
        });

      Promise.all([getAllData])
        .then(() => {
          this.initStatus = 1;
          this.toggleBusy();
        })
        .catch((error) => {
          console.log(error);
          this.initStatus = -1;
          this.toggleBusy();
        });
    },

    //----------------- Pagination, Sorting, dan Filtering Function -----------------//
    onPageChange: function () {
      if (this.loading == false) {
        this.page = this.currentPage;
        this.query = "?page=" + this.page;
        this.getKategoriFPP();
      }
    },

    toggleBusy() {
      if (this.isBusy == true) {
        this.isBusy = false;
      } else {
        this.isBusy = true;
      }
    },

    updateTotalItem: function (total) {
      this.perPage = this.size;
      this.rows = total;

      this.totalViewText = this.totalViewText = this.from + " - " + this.limit;
    },

    //-----------------  Callback Function -----------------//
    getKategoriFPP: function () {
      this.toggleBusy();

      this.$axios
        .get("api/internal-memo/kategori" + this.query)
        .then((response) => {
          if (response.status == 200) {
            let datas = response.data.data;

            this.kategoriFPP = [];

            for (var data of datas.data) {
              for (let jenis of data.kategori_jenis) {
                var kategori_jenis = jenis.name;
              }

              this.kategoriFPP.push({
                id: data.id,
                id_jenis: data.id_kategori_jenis_fpp,
                category: kategori_jenis + " (" + data.name + ")",
                name: data.name,
                kategori_jenis: kategori_jenis,
                sla: data.sla,
              });
            }

            this.perPage = datas.per_page;
            this.size = this.perPage;
            this.from = datas.from;
            this.limit = datas.to;

            this.toggleBusy();
            this.updateTotalItem(datas.total);
          }
        })
        .catch((error) => {
          console.log(error);
          this.toggleBusy();
        });
    },

    //-----------------  Reset Function -----------------//
    resetForm: function () {
      this.form = {
        name: null,
        sla: null,
        id_kategori_jenis_fpp: null,
      };

      this.formEdit = {
        id: null,
        name: null,
        sla: null,
        id_kategori_jenis_fpp: null,
      };

      this.formDelete = {
        activeName: null,
        activeId: null,
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },

    //-----------------  Hide Modal Function -----------------//
    hideModal: function () {
      this.$refs["my-modal-kategori-fpp"].hide();
      this.resetForm();
    },

    //-----------------  Confirm Create Function -----------------//
    onConfirm: function () {
      this.$v.form.$touch();

      if (this.$v.form.$anyError) {
        return;
      } else {
        this.showSweetAlertCreate();
        this.$bvModal.hide("m-create-kategori-fpp");
      }
    },

    //-----------------  Confirm Edit Function -----------------//
    onConfirmEdit: function () {
      this.$v.formEdit.$touch();

      if (this.$v.formEdit.$anyError) {
        return;
      } else {
        this.showSweetAlertEdit();
        this.$bvModal.hide("m-edit-kategori-fpp");
      }
    },

    //-----------------  Create Function -----------------//
    showSweetAlertCreate: function () {
      let form = {
        name: this.form.name,
        sla: this.form.sla,
        id_kategori_jenis_fpp: this.form.id_kategori_jenis_fpp,
      };

      this.$swal({
        html: "<strong>Konfirmasi</strong><span>, untuk melanjutkan penyimpanan data kategori.</span>",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1e73be",
        cancelButtonText: "Batal",
        confirmButtonText: "Konfirmasi",
        closeOnConfirm: false,
        closeOnCancel: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$swal({
            title: "Mohon Menunggu",
            html: "Penyimpanan data kategori sedang diproses...",
            allowOutsideClick: false,
            showConfirmButton: false,
            willOpen: () => {
              this.$swal.showLoading();
            },
          });

          this.$axios
            .post("/api/internal-memo/kategori", form)
            .then((response) => {
              if (response.status == 200) {
                this.$swal({
                  title: "Sukses!",
                  text: "Data kategori berhasil disimpan.",
                  icon: "success",
                  showConfirmButton: false,
                  timer: 1000,
                });

                this.resetForm();
                this.getKategoriFPP();
              }
            })
            .catch((error) => {
              console.log(error);
              this.$swal({
                title: "Gagal",
                text: "Data kategori gagal disimpan.",
                icon: "error",
                showConfirmButton: false,
                timer: 1000,
              });
              this.resetForm();
            });
        } else {
          this.$swal({
            title: "Batal",
            text: "Proses ini telah dibatalkan :)",
            icon: "error",
            showConfirmButton: false,
            timer: 1000,
          });
          this.resetForm();
        }
      });
    },

    //-----------------  Edit Function -----------------//
    showModalEdit: function (id, id_jenis, name, sla) {
      console.log(name);
      this.formEdit.id = id;
      this.formEdit.id_kategori_jenis_fpp = id_jenis;
      this.formEdit.name = name;
      this.formEdit.sla = sla;

      this.$bvModal.show("m-edit-kategori-fpp");
    },

    showSweetAlertEdit: function () {
      let formEdit = {
        name: this.formEdit.name,
        id: this.formEdit.id,
        id_kategori_jenis_fpp: this.formEdit.id_kategori_jenis_fpp,
        sla: this.formEdit.sla,
      };

      this.$swal({
        html: "<strong>Konfirmasi</strong><span>, untuk melanjutkan perubahan data kategori.</span>",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1e73be",
        cancelButtonText: "Batal",
        confirmButtonText: "Konfirmasi",
        closeOnConfirm: false,
        closeOnCancel: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$swal({
            title: "Mohon Menunggu",
            html: "Perubahan data kategori sedang diproses...",
            allowOutsideClick: false,
            showConfirmButton: false,
            willOpen: () => {
              this.$swal.showLoading();
            },
          });

          this.$axios
            .post("/api/internal-memo/kategori/" + formEdit.id, formEdit)
            .then((response) => {
              if (response.status == 200) {
                this.$swal({
                  title: "Sukses!",
                  text: "Data kategori berhasil diubah.",
                  icon: "success",
                  showConfirmButton: false,
                  timer: 1000,
                });
              }

              this.resetForm();
              this.getKategoriFPP();
            })
            .catch((error) => {
              console.log(error);
              this.$swal({
                title: "Gagal",
                text: "Data kategori gagal diubah.",
                icon: "error",
                showConfirmButton: false,
                timer: 1000,
              });
              this.resetForm();
            });
        } else {
          this.$swal({
            title: "Batal",
            text: "Proses ini telah dibatalkan :)",
            icon: "error",
            showConfirmButton: false,
            timer: 1000,
          });
          this.resetForm();
        }
      });
    },

    //-----------------  Delete Function -----------------//
    showSweetAlertDelete: function (id, name) {
      this.formDelete.activeId = id;
      this.formDelete.activeName = name;

      var kategoriName = this.formDelete.activeName;
      var content = document.createElement("div");
      content.innerHTML =
        '<strong>Konfirmasi</strong>, untuk melanjutkan proses penghapusan data kategori <strong>"' +
        kategoriName +
        '"</strong>?';

      this.$swal({
        title: "Konfirmasi",
        html: content,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1e73be",
        cancelButtonText: "Batal",
        confirmButtonText: "Konfirmasi",
        closeOnConfirm: false,
        closeOnCancel: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$swal({
            title: "Mohon Menunggu",
            html: "Penghapusan data kategori sedang diproses...",
            allowOutsideClick: false,
            showConfirmButton: false,
            willOpen: () => {
              this.$swal.showLoading();
            },
          });

          this.$axios
            .delete(
              "api/internal-memo/kategori/delete/" + this.formDelete.activeId
            )
            .then((response) => {
              if (response.status == 200) {
                this.$swal({
                  title: "Sukses!",
                  text: "Data kategori berhasil dihapus.",
                  icon: "success",
                  showConfirmButton: false,
                  timer: 1000,
                });

                this.resetForm();
                this.getKategoriFPP();
              }
            })
            .catch((error) => {
              console.log(error);
              this.$swal({
                title: "Gagal",
                text: "Data kategori gagal dihapus.",
                icon: "error",
                showConfirmButton: false,
                timer: 1000,
              });
              this.resetForm();
            });
        } else {
          this.$swal({
            title: "Batal",
            text: "Proses ini telah dibatalkan :)",
            icon: "error",
            showConfirmButton: false,
            timer: 1000,
          });
          this.resetForm();
        }
      });
    },
  },

  //-----------------  Initial Function -----------------//
  created: function () {
    this.init();
  },
};
</script>

<style lang="scss">
.b-table-empty-row {
  text-align: center;
}

.table th,
.table td {
  vertical-align: middle;
}

.nameOfTheClassTh {
  text-align: center;
}

.nameOfTheClassThAction {
  width: 10%;
  text-align: center;
}

.nameOfTheClassTd {
  text-align: center;
}

.required label::after {
  content: "*";
  color: #f79483;
}
</style>
